.services-holder {

  width: 100%;
  text-align: center;
  margin-top: -6px;

  &--text {

    padding-top: 30px;
    font-size: 3rem !important;

    @media(max-width: 450px) {
      font-size: 2rem !important;
    }

    @media(max-width: 315px) {
      font-size: 1.5rem !important;
    }

    @media(max-width: 260px) {
      font-size: 1rem !important;
    }

  }

  &--description {

    font-size: 18px;

    @media(max-width: 262px) {
      font-size: 1rem;
    }
  }

  &--cards {
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__card {
      margin: 15px;

    }

  }



}

.modern-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.modern-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.modern-card-icon {
  font-size: 48px;
  margin-bottom: 20px;
}

.modern-card-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.modern-card-description {
  font-size: 16px;
  text-align: center;
}
