.explanation-mein-bild {
  position: relative;
  top: 0;
  left: 0;
  height: 300px;
  width: 100%;
  background-image: url('https://avosoft.de/avocloud/index.php/s/7x696S3DnFFn24b/preview');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.svg-container {
  position: absolute;
  
  width: 100%;
  z-index: 2;
}

.explanation {
  &--content {
    width: 100%;
    background: #f9f9f9;
    min-height: 500px;
  }
}


