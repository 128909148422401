
.contact-container{
  height: 455px;
  position: relative;
  padding-top: 20px;

  background: #141E30;
  background: -webkit-linear-gradient(to top, #243B55, #141E30);
  background: linear-gradient(to top, #243B55, #141E30);
  border-radius: 15px;
  padding-top: 3rem;
}

.contact-button {
  width: 100%;
  height: 60px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  color: white;
  font-size: 1.6em;
  bottom: 0;

  background: #DE6262;
  background: -webkit-linear-gradient(to right, #FFB88C, #DE6262);
  background: linear-gradient(to right, #FFB88C, #DE6262);

  box-shadow: none;
  box-sizing: border-box;
  outline: none;
  border: none;
}

form{
  color: white;
  padding: 0px 70px 10px 70px;
  margin-top: -15px;

  @media(max-width: 450px) {
    padding: 0px 10px 10px 10px
  }

}

h1{
  text-transform: uppercase;
  font-size: 3em;
  position: relative;

  @media(max-width: 285px) {
    font-size: 2rem;
  }

}
h1:before {
  content : "";
  position: absolute;
  left    : 0;
  bottom  : -10px;
  width   : 30%;
  border-bottom: 4px solid #DE6262;
}

input{
  padding: 20px 0 20px 0 ;
  width: 90%;
  box-shadow: none;
  box-sizing: border-box;
  outline: none;
  border: none;
  margin-left: 10%;
  font-size: 18px;
  color: white;
  background: none;
  opacity: 0.4;
  border-bottom: 2px solid white;
}

textarea{
  width: 90%;
  margin-left: 10%;
  padding: 20px 0 0 0 ;
  background: none;
  resize: none;
  overflow: hidden;
  height: 115px;
  font-size: 18px;
  color: white;
  box-shadow: none;
  box-sizing: border-box;
  outline: none;
  border: none;
  opacity: 0.4;
  border-bottom: 2px solid white;
  position: relative;
}

#charNum{
  float: right;
  color: #DE6262;
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: bold;
}

.input{
  position: relative;
}
.input i{
  position: absolute;
  transform: translatey(50%);
  font-size: 1.2em;
  color: white;
  padding-top: 6px;
}
input:focus, input:valid, textarea:focus{
  opacity: 0.8;
}
