@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@300&display=swap");

@import './_services.scss';
@import './explanation.scss';
@import "./footer";
@import "./contact";
@import "./sidebar";
@import "./imagecard";



/* Background CSS */
body {
  margin: 0;
  padding: 0;
}

.background {
  background-image: url("../images/grundriss-zeichnen-teaser.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: brightness(70%);
  color: #343a40;
}

.navbar {
  position: fixed;
  width: 100vw;
  z-index: 1030;
  margin-bottom: 0;
  background: rgba(40, 40, 40, 0.8) !important;
  min-height: 80px;
}

/* Media Query für kleine Bildschirme */
@media (max-width: 991.98px) {
  .navbar {
    padding: 0;
  }
  .navbar.navbar-light {
    background-color: transparent;
  }
}

.navbar-light {
  background-color: transparent;
}

.navbar-toggler {
  z-index: 1030;
  position: absolute;
  right: 1rem;
  top: 0.5rem;
}

.navbar-logo {
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.navbar-logo img {
  max-height: 50px;
  width: auto;
}

.navbar-font {
  font-family: 'Barlow', sans-serif;
}

.default-span-font {
  font-family: 'Dosis', sans-serif;
}

.header-text {
  font-size: 3rem;

  @media (max-width: 355px) {
    font-size: 2rem;
  }

  @media(max-width: 255px) {
    font-size: 1.5rem;
  }

  &__description {
    font-size: 1.5rem;

    @media(max-width: 210px) {
      font-size: 1rem;
    }

  }

}

.navbar-defaultitem {
  cursor: pointer;
  position: relative;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: navajowhite !important;
  }

}

.navbar-defaultitem::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ffffff; /* Die Farbe des Strichs anpassen */
  transition: width 0.8s;
}

.navbar-defaultitem:hover {
  color: #000; /* Die Farbe des Textes beim Hover anpassen */
}

.navbar-defaultitem:hover::after {
  width: 100%;
}

.scrolldown {
  position: absolute;
  bottom: 100px;
  animation: bounce 2s ease infinite;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

@media(max-width: 895px) {
  .scrolldown {
    visibility: hidden;
  }
}

@keyframes bounce {
  50% {
    transform: translateY(-25%);
  }
}
